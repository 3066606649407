<template>
  <div>
    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <h1>{{ $t("header.cart") }}</h1>
            </div>
          </div>
        </div>
      </header>
    </div>
    <loading-overlay :loading="loading">
      <div
        v-if="error"
        class="container"
      >
        <Message
          class="m-t-2"
          type="error"
          :icon="false"
          :show-close="false"
        >
          {{ error }}
        </Message>
      </div>
      <div v-else>
        <div
          v-if="cartProducts.length > 0"
          class="container"
        >
          <div class="row">
            <div class="col">
              <div class="steps-progress">
                <div class="steps-progress__item steps-progress__item--active">
                  1
                </div>
                <div class="steps-progress__item">
                  2
                </div>
                <div class="steps-progress__item">
                  <i class="uil uil-check" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="cartProducts.length === 0"
          class="container m-t-4"
        >
          <EmptyState icon="shopping-cart">
            {{ $t('cart.empty.title') }}
            <span class="button button--danger">
              <router-link :to="{name: 'items'}">
                {{ $t('cart.empty.button') }}
              </router-link>
            </span>
          </EmptyState>
        </div>
        <div
          v-else
          class="container m-t-4"
        >
          <router-view />

          <div class="row">
            <div class="col-lg-7 m-b-4">
              <div
                v-for="(group, i) in generated.groupedOrders"
                :key="'group'+(i+1)"
              >
                <SectionTitle>Order #{{ (i+1) }}</SectionTitle>
                <div
                  class="highlight"
                  style="margin-bottom: 10px; background: white; font-size: 1.75rem;"
                >
                  <div v-if="group.date">
                    <strong>{{ $t('cart.deliveryDate') }}: </strong> {{ formatDate(group.date) }}
                  </div>
                  <div v-if="group.shippingcost">
                    <strong>Shippingcost:</strong> + <price
                      :currency="generated.currency"
                      :price="group.shippingcost"
                    />
                  </div>
                </div>

                <card
                  v-for="line in group.lines"
                  :key="line.item.id"
                  style="margin-bottom: 30px"
                >
                  <router-link
                    v-if="!line.orderline.customizer"
                    :to="{ name: 'cartItem', params: { id: line.item.id }}"
                    class="cartLink"
                  >
                    <itemcard
                      v-if="line.item.externalid"
                      class="product__item"
                      :summaryitem="line.item"
                    />
                  </router-link>
                  <router-link
                    v-else
                    :to="{ name: 'cartCustomizerItem', params: { externalid: line.item.externalid }}"
                    class="cartLink"
                  >
                    <itemcardcustomizer
                      class="product__item"
                      :summaryitem="line.item"
                    />
                  </router-link>
                  <div
                    class="product__item__cart m-t-2"
                  >
                    <div
                      v-if="line.item"
                      class="product__item__cart__details"
                    >
                      <ul>
                        <!-- Aantal -->
                        <li>
                          <strong>{{ $t('cart.quantity') }}:</strong>
                          {{ line.orderline.quantity }}
                        </li>
                        <!-- Preorder -->
                        <li v-if="line.orderline.preOrder">
                          <strong>
                            <i class="uil uil-check color--green" />
                            {{ $t('cart.preOrder') }}
                          </strong>
                        </li>
                        <!-- Remarks -->
                        <li v-if="line.orderline.remarks">
                          <strong>{{ $t('cart.remarks') }}:</strong>
                          {{ line.orderline.remarks }}
                        </li>
                        <!-- Delivery -->
                        <li v-if="line.orderline.deliveryDateType==='DEFAULT'">
                          <strong>{{ $t('cart.deliveryDate') }}: </strong>
                          <span v-if="line.orderline.customizer">{{ line.orderline.quantity > 1 ? '/' : formatDate(line.orderline.deliverydate) }}</span>
                          <span v-else>{{ line.orderline.quantity > 5 && line.maxAvailable === 5 ? '/' : weeksToPredicted(line) }}</span>
                        </li>
                        <li v-else-if="line.orderline.deliveryDateType === 'SELECTED'">
                          <strong>{{ $t('cart.deliveryDate') }}:</strong>
                          {{ line.orderline.preOrder ? (new Date(line.orderline.deliverydate)).getFullYear() + ' ' + $t(`cart.month`) + ' ' + ((new Date(line.orderline.deliverydate)).getMonth() + 1) : formatDate(line.orderline.deliverydate) }}
                        </li>
                      </ul>
                    </div>
                    <div class="product__item__cart__actions">
                      <!-- Edit -->
                      <listItem
                        icon-before
                        small
                      >
                        <div
                          class="pointer"
                          @click="assignCustomer(line.orderline.key)"
                        >
                          <i
                            class="uil uil-user-plus"
                          />
                          {{ $t('cart.assignCustomer') }}
                        </div>
                      </listItem>
                      <listItem
                        icon-before
                        small
                      >
                        <template v-if="!line.orderline.customizer">
                          <router-link :to="{ name: 'cartItem', params: { key: line.orderline.key}}">
                            <i class="uil uil-edit" />
                            {{ $t('cart.change') }}
                          </router-link>
                        </template>
                        <template v-else>
                          <router-link
                            :to="{ name: 'cartCustomizerItem', params: { key: line.orderline.key }}"
                          >
                            <i class="uil uil-edit" />
                            {{ $t('cart.change') }}
                          </router-link>
                        </template>
                      </listItem>
                      <listItem
                        icon-before
                        small
                      >
                        <div
                          class="pointer"
                          @click="removeItem(line.orderline.key)"
                        >
                          <i
                            class="uil uil-trash"
                          />
                          {{ $t('cart.remove') }}
                        </div>
                      </listItem>
                    </div>
                  </div>
                  <Message
                    v-for="error in line.errors"
                    :key="i + error"
                    class="m-t-2"
                    type="error"
                    :icon="false"
                    :show-close="false"
                  >
                    {{ $t('cart.messages.' + error) }}
                  </Message>

                  <Message
                    v-for="warning in line.warnings"
                    :key="i + warning"
                    class="m-t-2"
                    type="warning"
                    :icon="false"
                    :show-close="false"
                  >
                    {{ $t('cart.messages.' + warning) }}
                  </Message>
                </card>
              </div>
            </div>

            <div class="col-lg-5 m-b-4">
              <div v-if="isPreviewMode & true===false">
                <SectionTitle>Promocode</SectionTitle>

                <div class="d-flex space-between m-b-1">
                  <input
                    type="text"
                    placeholder="Promocode"
                    class="w-100 m-r-1"
                  >
                  <button class="button button--secondary button--icon">
                    <i class="uil uil-check" />
                  </button>
                </div>

                <table class="table table--striped m-b-4">
                  <tr>
                    <td>
                      Code:
                      <strong>BLACKFRIDAY</strong>
                    </td>
                    <td
                      class="nowrap"
                      width="1"
                    >
                      - €85.00
                    </td>
                    <td width="1">
                      <span class="button button--small button--icon">
                        <i class="uil uil-trash color--red" />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Code:
                      <strong>CYBERMONDAY</strong>
                    </td>
                    <td
                      class="nowrap"
                      width="1"
                    >
                      - €45.00
                    </td>
                    <td width="1">
                      <span class="button button--small button--icon">
                        <i class="uil uil-trash color--red" />
                      </span>
                    </td>
                  </tr>
                </table>
              </div>

              <SectionTitle>{{ $t('cart.prices') }}</SectionTitle>

              <cart-prices-table :generated="generated" />
              <div
                v-if="!showRetailPrices && (balanceExceedsCreditlimit && checkProhibitedActions === 'All-Finance') && isPreviewMode"
              >
                <myBalanceStatus class="m-t-4" />
              </div>
              <div v-if="!showRetailPrices && (!balanceExceedsCreditlimit && checkProhibitedActions === 'All-Finance') && isPreviewMode">
                <Message
                  type="error"
                  :icon="true"
                  :content="$t('account.account.finance.blockedmessage')"
                  :show-close="false"
                >
                  <strong>{{ $t('account.account.myBalance.messagetitle1') }}</strong> {{ $t('account.account.finance.blockedtitle') }}
                </Message>
              </div>
              <div v-if="!showRetailPrices && (!balanceExceedsCreditlimit && checkProhibitedActions === 'All-OAM') && isPreviewMode">
                <Message
                  type="error"
                  :icon="true"
                  :content="$t('account.account.oam.shippingholdmessage')"
                  :show-close="false"
                >
                  <strong>{{ $t('account.account.myBalance.messagetitle1') }}</strong> {{ $t('account.account.oam.shippingholdtitle') }}
                </Message>
              </div>

              <!-- Use below disabled line to activate RTS -->
              <!-- :disabled="!isValid || balanceExceedsCreditlimit" -->
              <!-- :disabled="!isValid" -->
              <button
                v-if="cartProducts.length > 0"
                class="button button--danger w-100"
                :disabled="!isValid || checkProhibitedActions === 'All-Finance'"
                @click="confirmCart"
              >
                {{ $t('cart.nextStep') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </loading-overlay>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import card from '@/elements/Card.vue';
import listItem from '@/elements/ListItem.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import EmptyState from '@/elements/EmptyState.vue';
import itemcard from '@/views/items/itemCard.vue';
import itemcardcustomizer from '@/views/customizer/itemCardCustomizer.vue';
import { availabilityText, searchProhibitedAction } from '@/utils/cartUtils';
import Message from '@/components/Message.vue';
import cartPricesTable from '@/components/cartPricesTable.vue';
import myBalanceStatus from '@/components/myBalanceStatus.vue';
import { formatDate } from '@/utils/dateUtils';
import price from '@/elements/Price.vue';
import LoadingOverlay from '@/elements/LoadingOverlay.vue';

export default {
  components: {
    card,
    listItem,
    EmptyState,
    SectionTitle,
    itemcard,
    itemcardcustomizer,
    Message,
    cartPricesTable,
    myBalanceStatus,
    price,
    LoadingOverlay,
  },
  metaInfo() {
    return {
      title: this.$t('header.cart'),
    };
  },
  data() {
    return {
      creditlimit: 0,
    };
  },
  computed: {
    checkProhibitedActions() {
      const objProhibitedActions = searchProhibitedAction(this.selectedCustomer);
      console.log(objProhibitedActions?.value);
      return objProhibitedActions?.value;
    },
    balanceExceedsCreditlimit() {
      return ((this.selectedCustomer.attributes.CreditLimitLCY > 0) && (this.totalBalance > this.creditlimit));
    },
    isCreditlimit() {
      return this.selectedCustomer.attributes.CreditLimitLCY > 0;
    },
    ...mapGetters('cart', {
      cartProducts: 'cartProducts',
      generated: 'cartGenerated',
      loading: 'cartLoading',
      error: 'cartError',
    }),
    ...mapState({
      showRetailPrices: (state) => state.items.showRetailPrices,
      isPreviewMode: (state) => state.global.isPreviewMode,
      selectedCustomer: (state) => state.customer.selectedCustomer,
    }),
    isValid() {
      return !this.generated.hasErrors;
    },
    totalBalance() {
      const { exchangerate } = this.selectedCustomer.attributes;
      if (this.currency !== 'EUR') {
        return ((this.selectedCustomer.attributes.BalanceProductionOrdersLCY + this.selectedCustomer.attributes.BalanceOpenInvoicesLCY) * exchangerate)
          + this.generated.toPay;
      }
      return this.selectedCustomer.attributes.BalanceProductionOrdersLCY
          + this.selectedCustomer.attributes.BalanceOpenInvoicesLCY + this.generated.toPay;
    },
  },
  async  mounted() {
    this.$store.dispatch('cart/generate');
    this.currency = this.selectedCustomer.attributes.currency;
    const { exchangerate } = this.selectedCustomer.attributes;
    if (this.currency !== 'EUR') {
      this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY * exchangerate;
    } else {
      this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY;
    }
  },
  methods: {
    removeItem(key) {
      // eslint-disable-next-line no-alert
      const r = window.confirm(this.$t('cart.removeConfirmation'));
      if (r === true) {
        this.$store.dispatch('cart/removeItem', key);
      }
    },
    formatDate(date) {
      return formatDate(date);
    },
    datePredicted(item) {
      if (!item.item.$$availability) {
        return null;
      } if (
        !item.item.$$availability.find(
          (o) => o.quantity === item.orderline.quantity && o.date,
        )
      ) {
        return null;
      }
      const { date } = item.item.$$availability.find(
        (o) => o.quantity === item.quantity,
      );
      return new Date(date);
    },
    weeksToPredicted(item) {
      return availabilityText(item.item.$$availability, item.orderline.quantity, this.showRetailPrices);
    },
    confirmCart() {
      this.$router.push({ name: 'cartConfirm' });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_colors.scss";
.vdp-datepicker__calendar .cell.selected,
.vdp-datepicker__calendar .cell.selected:hover {
  background: $red;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid $red;
}

.cartLink {
  color: inherit;
  text-decoration: none;
}
</style>
